body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.Banner {
  height: auto;
  background-color: hsl(250, 80%, 58%);
  display: flex;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .Banner {
    flex-direction: column;
    height: auto;
  }
}
